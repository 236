@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.assignmentsQuestionWrap {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 24px;
}

.title {
  margin-left: 76px;
  @include mobile {
    margin-left: 16px;
  }
}

.contentRight {
  justify-content: end;
}

.content {
  height: 100%;
  flex: 1;
  overflow: auto;
  padding-top: 35px;
  @include mobile {
    margin: 10px;
  }
}

.contentWrap {
  height: 100%;
  display: flex;
  &__left {
    width: 50%;
    border: 1px solid v.$border-cl;
    overflow: auto;
  }
  &__right {
    margin-left: 25px;
    width: 50%;
    border: 1px solid v.$border-cl;
    overflow: auto;
  }
}

.contentTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid v.$border-cl;
  background: rgba(0, 0, 0, 0.03);
  height: 50px;
}

.form {
  padding: 10px;
}

.li {
  display: flex;
}

.tab1 {
  width: 50%;
}
.chart1 {
  margin-top: 20px;
}
.titleTable {
  text-align: center;
  margin: 20px 0 10px;
}
.descTable {
  color: red;
  margin-bottom: 80px;
}
