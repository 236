@use '../../scss/variables' as v;
@import '../../scss/mixins';

.title {
  margin-left: 76px;
  @include mobile {
    margin-left: 16px;
  }
}

.contentRight {
  justify-content: end;
}

.contentRightMg {
  justify-content: end;
  margin-top: 10px;
}
