@use '../../scss/variables' as v;

.mainButton {
  width: 100%;
  height: 100%;
  background-color: v.$orange-cl !important;
  border-color: v.$orange-cl !important;
  border-radius: 4px;
  color: v.$white-cl !important;
}

.wrapper {
  max-height: 500px;
  overflow-y: scroll;
}
